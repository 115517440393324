import { template as template_74be9cbcd5014053bfee52e87f644bc9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_74be9cbcd5014053bfee52e87f644bc9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
