import { template as template_f8cb3c6f876b4a9baf85e71e43283f3b } from "@ember/template-compiler";
const FKLabel = template_f8cb3c6f876b4a9baf85e71e43283f3b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
