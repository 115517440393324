import { template as template_c854aa932f954e7ab8b0fed189d621cf } from "@ember/template-compiler";
const WelcomeHeader = template_c854aa932f954e7ab8b0fed189d621cf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
